import { createApi } from '@reduxjs/toolkit/query/react';

import { serviceUrl } from '../utils/apiConfig';
import { getItem } from '../utils/storage';

import customBaseQuery from './customBaseQuery';

const api = createApi({
  baseQuery: customBaseQuery({
    baseUrl: serviceUrl,
    prepareHeaders: headers => {
      const token = getItem('token') as string;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'GetItemDetailsByGroup',
    'GetCategoryDashboardDetails',
    'GetItemGroupDashboardDetails',
    'GetCustomerDashboardDetails',
    'CustomerOutlets',
    'GetCustomerItemGroups',
    'GetCustomerOutletsStaff',
    'GetCustomerTypes',
    'GetCuisineTypes',
    'GetCustomerItemDetails',
    'GetDesignationTypes',
    'GetInventoryDetailsTypes',
    'GetItemInventoryTxn',
    'GetAllAgentPickers',
    'GetOrderByStatus',
    'GetItemDetailsByPacks',
    'GetItemDetailsByCategoryId',
    'GetSubCategoryDashboard',
    'GetOrderMetrics',
    'GetItemPacksByID',
    'GetMov',
    'GetAccountManager',
    'GetDeliveryTimeSlot'
  ],
  endpoints: () => ({})
});

export default api;
