import { ReactNode } from 'react';

type ButtonProps = {
  handleClickBtn?: () => void;
  variant:
    | 'outlined'
    | 'contained'
    | 'none'
    | 'outlined-danger'
    | 'outlined-black'
    | 'contained-color'
    | 'outlined-color';
  disabled?: boolean;
  label: string | ReactNode;
  classes?: string;
  children?: ReactNode;
  isLoading?: boolean;
  notificationCount?: number;
  variantColor?: string;
};

const Button = ({
  handleClickBtn,
  variant,
  disabled,
  label,
  classes,
  children,
  isLoading,
  notificationCount,
  variantColor
}: ButtonProps) => {
  const applyClasses = () => {
    switch (variant) {
      case 'outlined':
        return 'border border-[#0C604A] text-[#0C604A] px-4';
      case 'outlined-danger':
        return 'border border-[#D11C00] text-[#D11C00] px-4';
      case 'outlined-black':
        return ' border border-black text-black bg-white';
      case 'contained':
        return 'bg-[#0C604A] text-white px-4';
      case 'contained-color':
        return `bg-[${variantColor ? variantColor : '#0C604A'}] text-white px-4`;
      case 'outlined-color':
        return `border border-[${variantColor ? variantColor : '#0C604A'}] text-[${variantColor ? variantColor : '#0C604A'}] px-4`;
      case 'none':
        return 'text-[#0C604A]';
      default:
        'border border-[#0C604A] text-[#0C604A] px-4';
    }
  };

  return (
    <button
      className={`${classes} ${applyClasses()} ${disabled ? 'disabled cursor-not-allowed opacity-50' : 'hover:cursor-pointer'} relative ml-2 rounded-md py-1 text-sm`}
      onClick={handleClickBtn}
      disabled={disabled}
    >
      <p className='flex items-center justify-center'>
        {children}{' '}
        <span>
          {label} {isLoading && <i className='fa fa-spinner fa-spin text-6' />}
        </span>
        {notificationCount !== undefined && notificationCount > 0 && (
          <span className='absolute right-0 top-0 flex h-5 w-5 -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full border border-red-600 bg-white text-xs text-red-600'>
            {notificationCount}
          </span>
        )}
      </p>
    </button>
  );
};

export default Button;
