import { SubCategoryDashboardApiRes } from '../../redux/Slices/CategoriesSlice/categoryTypes';
import api from '../api';

export const categoriesEditApi = api.injectEndpoints({
  endpoints: builder => ({
    editCategoryBasicDetails: builder.query({
      query: (categoryid: number) => ({
        url: '/api/web/v1/category/edit/getcateogry-basicdetails',
        method: 'GET',
        params: { categoryid }
      })
    }),
    updateCategoryBasicDetails: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/category/edit/updatecategory-basicdetails',
        method: 'PUT',
        body: payload
      })
    }),
    getSubCategoryDashboard: builder.query<SubCategoryDashboardApiRes, number>({
      query: parentcategoryid => ({
        url: '/api/web/v1/category/edit/getsub-categorydashboard',
        method: 'GET',
        params: { parentcategoryid }
      }),
      providesTags: ['GetSubCategoryDashboard']
    }),
    updateSubCategoryBasicDetails: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/category/edit/updatesubcategory-basicdetails',
        method: 'PUT',
        body: payload
      })
    })
  })
});

export const {
  useEditCategoryBasicDetailsQuery,
  useLazyEditCategoryBasicDetailsQuery,
  useUpdateCategoryBasicDetailsMutation,
  useGetSubCategoryDashboardQuery,
  useUpdateSubCategoryBasicDetailsMutation
} = categoriesEditApi;
