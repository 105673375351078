type SetItemProps = {
  key: string;
  value: string;
};

/**
 * use to set the item
 * @param param0
 */
export const setItem = ({ key, value }: SetItemProps) => {
  return localStorage.setItem(key, value);
};

export const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const clearStorage = () => {
  return localStorage.clear();
};
