import {
  CustomerAddressDetails,
  CustomerOutletStaff,
  OrderAuditWorkflowDetails,
  OrderDetails,
  OrderIdDetailsByID,
  OrderIdDetailsByIDResponse,
  OrderItemDetails,
  OrderLabels,
  OrderOuletAddress,
  OrderPrepaidPaymentDetails,
  WorkflowData
} from '../../../services/apiTypes';

export const emptyOrderItemDetails: OrderItemDetails = {
  itemtype: 0,
  pickedquantity: null,
  name: '',
  orderedquantity: 0,
  packsizeid: 0,
  sku: '',
  packsize: '',
  returnedquantity: null,
  deliveredquantity: null,
  orderitemid: 0,
  packmultiplier: 0,
  packsizeweight: ''
};

export const emptyCustomerOutletStaff: CustomerOutletStaff = {
  id: 0,
  customerOutletID: 0,
  name: '',
  phoneNumber: '',
  email: '',
  role: '',
  createdDate: '',
  updatedDate: null,
  isPrimary: false
};

export const emptyOrderPrepaidPaymentDetails: OrderPrepaidPaymentDetails = {
  paidamount: 0,
  originalorderamount: 0,
  deliverycharge: 0,
  couponapplied: 0,
  paymentstatus: '',
  discount: 0,
  paymentdetails: ''
};

export const emptyWorkflowData: WorkflowData = {
  iscurrentstatus: false,
  description: '',
  label: '',
  time: '',
  id: 0
};

export const emptyLabels: OrderLabels = {
  DELIVERED: '',
  CLOSED: '',
  PACKING: '',
  PENDING: '',
  PLACED: '',
  DISPATCHED: '',
  READY_TO_DISPATCH: '',
  PACKED: ''
};

export const emptyOrderAuditWorkflowDetails: OrderAuditWorkflowDetails = {
  workflowdata: [],
  labels: emptyLabels,
  activeorderstatus: 0
};

export const emptyAddress: OrderOuletAddress = {
  outletName: '',
  floorNumber: '',
  city: '',
  state: '',
  pincode: '',
  googleMapCoordinates: '',
  areaCode: '',
  latitude: '',
  longitude: ''
};

export const emptyCustomerAddressDetails: CustomerAddressDetails = {
  address: emptyAddress,
  phone: '',
  businessname: '',
  email: '',
  latitude: '',
  longitude: '',
  googleMapCoordinates: ''
};

export const emptyOrderDetails: OrderDetails = {
  deliverytimeslot: '',
  instructions: '',
  orderid: '',
  orderdatetime: '',
  warehouse: ''
};

export const emptyOrderIdDetailsByID: OrderIdDetailsByID = {
  orderitemdetails: [emptyOrderItemDetails],
  customeroutletstaff: [],
  orderprepaidpaymentdetails: emptyOrderPrepaidPaymentDetails,
  orderauditworkflowdetails: emptyOrderAuditWorkflowDetails,
  customeraddressdetails: emptyCustomerAddressDetails,
  orderdetails: emptyOrderDetails,
  iscustomerpostpaid: false,
  isorderrejected: false,
  orderrejectionreason: ''
};

export const emptyOrderIdDetailsByIDResponse: OrderIdDetailsByIDResponse = {
  data: emptyOrderIdDetailsByID,
  message: '',
  status: ''
};
