export const newItemGroupsData = {
  description: '',
  itemids: [],
  listingOrder: 0,
  name: '',
  validName: ''
};

export const newItemsData = {
  alias: [],
  categoryIds: [],
  itemGeneralDetails: [],
  name: '',
  packingOrder: 0,
  id: 0,
  itemID: 0
};

export const newItemsDataApiRes = {
  alias: [],
  categoryIds: [],
  itemGeneralDetails: [],
  name: '',
  packingOrder: 0,
  id: 0,
  itemID: 0
};

export const isButtonsClickedData = {
  isAddItemButtonClicked: false,
  isAddCategoryButtonClicked: false,
  isAddItemGroupButtonClicked: false
};

export const item = {
  itemIDs: [],
  name: '',
  imageURL: '',
  packSizes: null,
  packingOrder: 0,
  categoryDetails: [],
  itemTypeDetails: []
};
