import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ordersApi } from '../../../services/ordersApi';
import { ordersListApi } from '../../../services/ordersListApi';

import { emptyOrderIdDetailsByID } from './OrderInitialState';
import { InitalStateType } from './types';

const intialState: InitalStateType = {
  customerSearchValue: '',
  customerSearchResult: { data: [] },
  selectedCustomerOutlets: [],
  outletsStaff: [],
  orderItems: [],
  customerItemsforOrder: { data: [] },
  allOrders: [],
  ordersByOrderStatus: { data: [] },
  dashboardMetrics: [],
  OrderDetailsByID: emptyOrderIdDetailsByID,
  selectedIDForOrderDetails: {
    selectedIDForOrder: '',
    currentStatus: '',
    currentStatusId: 0
  },
  OrderRejectionReasons: [],
  orderDashboardMetrics: {
    packedorder: 0,
    totalpendingorder: 0,
    totalpickersassigned: 0,
    ordersplacedtoday: 0,
    orderdispatched: 0,
    todaycompletedorders: 0
  },
  isInvoiceAvailable: false
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: intialState,
  reducers: {
    setCustomerSearchValue: (state, action) => {
      state.customerSearchValue = action.payload;
    },
    setOrderItems: (state, action) => {
      state.orderItems = action.payload;
    },
    setselectedIDForOrderDetails: (state, action) => {
      state.selectedIDForOrderDetails = action.payload;
    },
    setIsInvoiceAvailable: (state, action) => {
      state.isInvoiceAvailable = action.payload;
    },
    resetselectedIDForOrderDetails: state => {
      state.OrderDetailsByID = emptyOrderIdDetailsByID;
      state.selectedIDForOrderDetails = {
        selectedIDForOrder: '',
        currentStatus: '',
        currentStatusId: 0
      };
      state.isInvoiceAvailable = false;
    },
    updatePickedQuantity(
      state,
      action: PayloadAction<{ itemId: number; pickedquantity: number }>
    ) {
      const item = state.OrderDetailsByID.orderitemdetails.find(
        item => item.id === action.payload.itemId
      );
      if (item) {
        item.pickedquantity = action.payload.pickedquantity;
      }
    },
    updateReturnedQuantity(
      state,
      action: PayloadAction<{ itemId: number; returnedquantity: number }>
    ) {
      const item = state.OrderDetailsByID.orderitemdetails.find(
        item => item.id === action.payload.itemId
      );
      if (item) {
        item.returnedquantity = action.payload.returnedquantity;
      }
    },
    updateOrderItem: (state, action) => {
      const { index, field, valueupdated } = action.payload;
      state.OrderDetailsByID.orderitemdetails[index][field] = valueupdated;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ordersApi.endpoints.flexiSearchCustomer.matchFulfilled,
      (state, action) => {
        state.customerSearchResult.data = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getOutletsOfCustomer.matchFulfilled,
      (state, action) => {
        state.selectedCustomerOutlets = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getOutletsStaff.matchFulfilled,
      (state, action) => {
        state.outletsStaff = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getItemsforCustomerOrder.matchFulfilled,
      (state, action) => {
        state.customerItemsforOrder.data = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersListApi.endpoints.getAllOrders.matchFulfilled,
      (state, action) => {
        state.allOrders = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersListApi.endpoints.getOrdersByStatus.matchFulfilled,
      (state, action) => {
        state.ordersByOrderStatus.data = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersListApi.endpoints.dashboardMetrics.matchFulfilled,
      (state, action) => {
        state.dashboardMetrics = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getOrderDetailsByID.matchFulfilled,
      (state, action) => {
        state.OrderDetailsByID = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersApi.endpoints.getRejectReasons.matchFulfilled,
      (state, action) => {
        state.OrderRejectionReasons = action.payload.data;
      }
    );
    builder.addMatcher(
      ordersListApi.endpoints.getOrderMetrics.matchFulfilled,
      (state, action) => {
        state.orderDashboardMetrics = action.payload.data;
      }
    );
  }
});

export const {
  setCustomerSearchValue,
  setOrderItems,
  setselectedIDForOrderDetails,
  resetselectedIDForOrderDetails,
  updatePickedQuantity,
  updateReturnedQuantity,
  updateOrderItem,
  setIsInvoiceAvailable
} = ordersSlice.actions;

export default ordersSlice.reducer;
