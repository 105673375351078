import { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Otp from '../../pages/Otp';
import { useAppSelector } from '../../redux/store/hooks';
import PaymentsDashboard from '../PaymentsDashboard';

const AuthenticatedLayout = lazy(
  () => import('../../components/Layout/AuthenticatedLayout')
);
const LoginPage = lazy(() => import('../../pages/LoginPage'));
const CustomersPage = lazy(() => import('../../pages/CustomersPage'));
const DashboardPage = lazy(() => import('../../pages/DashboardPage'));
const ItemCategories = lazy(() => import('../../pages/ItemCategories'));
const ItemGroupsPage = lazy(() => import('../../pages/ItemGroupsPage'));
const ItemsPage = lazy(() => import('../../pages/ItemsPage'));
const NotFoundPage = lazy(() => import('../../pages/NotFoundPage'));
const Inventory = lazy(() => import('../../pages/InventoryPage'));
const CreateOrderPage = lazy(() => import('../../pages/CreateOrderPage'));
const PricingPage = lazy(() => import('../../pages/PricingPage'));
const CustomerItemsPage = lazy(() => import('../../pages/CustomerItemsPage'));
const PickerAgentPage = lazy(() => import('../../pages/PickerAgentPage'));
const OrderListPage = lazy(() => import('../../pages/OrderListPage'));
const ItemsPricing = lazy(
  () => import('../../components/CustomersDashboard/ItemsPricing')
);
const Lookups = lazy(() => import('../../components/Lookups'));
const BasePrice = lazy(
  () => import('../../components/ItemsDashboard/PricingDashboard/BasePrice')
);
const InventoryItem = lazy(
  () =>
    import(
      '../../components/ItemsDashboard/InventoryDashboard/InventoryItemTxn/InventoryItemTxn'
    )
);

const PaymentsPage = lazy(() => import('../../pages/PaymentsPage'));

const OrderDetailsPage = lazy(() => import('../../pages/OrderDetailsPage'));

const Routing = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(state => state.root.auth);

  useEffect(() => {
    const getRedirectPath = () => {
      if (isLoggedIn) {
        return '/orders/order-list';
      }
      return null;
    };

    const redirectPath = getRedirectPath();
    if (redirectPath && ['/', '/otp'].includes(location.pathname)) {
      navigate(redirectPath);
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/otp' element={<Otp />} />

      <Route
        path='/items'
        element={
          <AuthenticatedLayout>
            <ItemsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/categories'
        element={
          <AuthenticatedLayout>
            <ItemCategories />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/item-groups'
        element={
          <AuthenticatedLayout>
            <ItemGroupsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/pricing'
        element={
          <AuthenticatedLayout>
            <PricingPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/pricing/:name'
        element={
          <AuthenticatedLayout>
            <BasePrice />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/inventory'
        element={
          <AuthenticatedLayout>
            <Inventory />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/items/inventory/:name'
        element={
          <AuthenticatedLayout>
            <InventoryItem />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/customers'
        element={
          <AuthenticatedLayout>
            <CustomersPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/customers/items-pricing'
        element={
          <AuthenticatedLayout>
            <ItemsPricing />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/customers/customer-items'
        element={
          <AuthenticatedLayout>
            <CustomerItemsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/orders/create-order'
        element={
          <AuthenticatedLayout>
            <CreateOrderPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/orders/order-list'
        element={
          <AuthenticatedLayout>
            <OrderListPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/orders/order-list/:orderid'
        element={
          <AuthenticatedLayout>
            <OrderDetailsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/lookups'
        element={
          <AuthenticatedLayout>
            <Lookups />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/agents/picker-agent'
        element={
          <AuthenticatedLayout>
            <PickerAgentPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/payments'
        element={
          <AuthenticatedLayout>
            <PaymentsDashboard />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/payments-details'
        element={
          <AuthenticatedLayout>
            <PaymentsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='/order-details'
        element={
          <AuthenticatedLayout>
            <OrderDetailsPage />
          </AuthenticatedLayout>
        }
      />
      <Route
        path='*'
        element={
          <AuthenticatedLayout>
            <NotFoundPage />
          </AuthenticatedLayout>
        }
      />
    </Routes>
  );
};

export default Routing;
