import api from './api';
import { CustomerPaymentDetailsResponse } from './apiTypes';
import { CustomerDetailsEdit } from './types/customersEditType';

export const customersEditApi = api.injectEndpoints({
  endpoints: builder => ({
    getCustomerDetailsEdit: builder.query<CustomerDetailsEdit, number>({
      query: (customerid: number) => ({
        url: '/api/web/v1/customer/edit/getcustomerdetails',
        method: 'GET',
        params: { customerid }
      })
    }),
    editCustomerBasicDetails: builder.query({
      query: (customerid: number) => ({
        url: '/api/web/v1/customer/edit/getcustomer-basicdetails',
        method: 'GET',
        params: { customerid }
      })
    }),
    updateCustomerBasicDetails: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/customer/edit/updatecustomer-basicdetails',
        method: 'PUT',
        body: payload
      })
    }),
    updateCustomerOutlets: builder.mutation({
      query: ({ payload, outletid }) => ({
        url: '/api/web/v1/customer-outlet/updateoutlet',
        method: 'PUT',
        body: payload,
        params: { outletid }
      }),
      invalidatesTags: ['CustomerOutlets']
    }),
    updateStaff: builder.mutation({
      query: ({ payload, staffid }) => ({
        url: '/api/web/v1/customer-outlet/staff/updatestaff',
        method: 'PUT',
        body: payload,
        params: { staffid }
      })
    }),
    customerSwitchStatus: builder.mutation({
      query: ({ customerid, status }) => ({
        url: '/api/web/v1/customer/edit/switch-status',
        method: 'PUT',
        params: { customerid, status }
      }),
      invalidatesTags: ['GetCustomerDashboardDetails']
    }),
    fetchCustomerPaymentDetails: builder.query<
      CustomerPaymentDetailsResponse,
      number | null
    >({
      query: customerid => ({
        url: '/api/web/v1/customer/edit/getcustomer-paymentdetails',
        method: 'GET',
        params: { customerid }
      })
    })
  })
});

export const {
  useLazyGetCustomerDetailsEditQuery,
  useLazyEditCustomerBasicDetailsQuery,
  useUpdateCustomerBasicDetailsMutation,
  useUpdateCustomerOutletsMutation,
  useUpdateStaffMutation,
  useCustomerSwitchStatusMutation,
  useFetchCustomerPaymentDetailsQuery
} = customersEditApi;
