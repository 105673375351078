import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { inventoryApi } from '../../../services/inventoryApi';

import { intialState } from './inventoryIntitialState';

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: intialState,
  reducers: {
    setIsInventoryNameClicked: (state, action) => {
      state.isInventoryNameClicked = action.payload;
    },
    setItemIDs: (state, action) => {
      state.itemIDs = action.payload;
    },
    resetInventoryState: state => {
      state = intialState;
    },
    resetselectedItemDetail: state => {
      state.selectedItemDetail = intialState.selectedItemDetail;
      state.selectedUpdatedItemDetail = intialState.selectedUpdatedItemDetail;
    },
    resetselectedItemTxnDetail: state => {
      state.selectedItemInventoryTxnDetail =
        intialState.selectedItemInventoryTxnDetail;
      state.selectedItemInventoryTxnDetailClicked = true;
    },
    setselectedItemTxnDetailClicked: state => {
      state.selectedItemInventoryTxnDetailClicked = false;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      inventoryApi.endpoints.getInventoryDetails.matchFulfilled,
      (state, action: PayloadAction<{ data: any }>) => {
        state.inventoryDetails = action.payload.data;
      }
    );
    builder
      .addMatcher(
        inventoryApi.endpoints.getInventoryItem.matchPending,
        (state, action) => {
          state.selectedItemDetailLoading = true;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getInventoryItem.matchFulfilled,
        (state, action: PayloadAction<{ data: any }>) => {
          state.selectedItemDetail = action.payload.data;
          state.selectedItemDetailLoading = false;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getInventoryItem.matchRejected,
        (state, action) => {
          state.selectedItemDetailLoading = false;
        }
      );
    builder
      .addMatcher(
        inventoryApi.endpoints.getupdateInventoryItem.matchPending,
        (state, action) => {
          state.selectedUpdatedItemDetailLoading = true;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getupdateInventoryItem.matchFulfilled,
        (state, action: PayloadAction<{ data: any }>) => {
          state.selectedUpdatedItemDetail = action.payload.data;
          state.selectedUpdatedItemDetailLoading = false;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getupdateInventoryItem.matchRejected,
        (state, action) => {
          state.selectedUpdatedItemDetailLoading = false;
        }
      );
    builder
      .addMatcher(
        inventoryApi.endpoints.getInventoryItemTxns.matchPending,
        (state, action) => {
          state.selectedItemInventoryTxnDetailLoading = true;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getInventoryItemTxns.matchFulfilled,
        (state, action: PayloadAction<{ data: any }>) => {
          state.selectedItemInventoryTxnDetail = action.payload.data;
          state.selectedItemInventoryTxnDetailLoading = false;
        }
      )
      .addMatcher(
        inventoryApi.endpoints.getInventoryItemTxns.matchRejected,
        (state, action) => {
          state.selectedItemInventoryTxnDetailLoading = false;
        }
      );
  }
});

export const {
  resetInventoryState,
  resetselectedItemDetail,
  resetselectedItemTxnDetail,
  setselectedItemTxnDetailClicked,
  setIsInventoryNameClicked,
  setItemIDs
} = inventorySlice.actions;

export default inventorySlice.reducer;
