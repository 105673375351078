import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSelectedRows } from '../../../redux/Slices/ItemsSlice/itemsSlice';
import { useAppSelector } from '../../../redux/store/hooks';

export type TableColumnType = {
  field: string;
  name: string;
  selector?: (
    row?: any,
    tabRow?: any,
    tabId?: any,
    index?: number | undefined
  ) => ReactNode;
  hide?: boolean;
  headerClasses?: string;
  fieldClasses?: string;
  sortable?: boolean;
};

type TableProps = {
  columns: TableColumnType[];
  data: any[];
  getSelectedData?: (selectedRows: any[]) => void;
  getSelectedRow?: (id: string, data: any, row?: any) => void;
  disableCheckBoxes?: any[];
  disableHeaderCheckbox?: boolean;
  hideCheckBoxes?: boolean;
  tabRow?: any;
  tabId?: string;
  expandableTable?: any;
  tHeadClasses?: string;
  dataKey: string;
  isChevronClicked?: boolean;
  containerClasses?: string;
  trClasses?: string;
  isBlur?: boolean;
};

type CheckBoxProps = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const Checkbox = ({ checked, onChange, disabled }: CheckBoxProps) => (
  <input
    type='checkbox'
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    className='form-checkbox h-3 w-3 cursor-pointer text-indigo-600'
  />
);

const Table = ({
  columns,
  data,
  getSelectedData,
  getSelectedRow,
  disableCheckBoxes = [],
  disableHeaderCheckbox = false,
  hideCheckBoxes = false,
  tabRow,
  tabId,
  expandableTable,
  tHeadClasses = 'bg-[#F7F5F0]',
  dataKey,
  isChevronClicked,
  containerClasses,
  trClasses,
  isBlur
}: TableProps) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const dispatch = useDispatch();
  const { selectedRows } = useAppSelector(state => state.root.items);

  const handleRowClick = (row: any) => {
    setSelectedRow(row.name === selectedRow ? null : row.name);
  };
  const handleCheckboxChange = (e: any, row: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      dispatch(setSelectedRows([...selectedRows, row]));
    } else {
      const result = selectedRows.filter(
        (selectedRow: any) => selectedRow?.[dataKey] !== row?.[dataKey]
      );
      dispatch(setSelectedRows(result));
    }
  };

  useEffect(() => {
    if (getSelectedData) {
      getSelectedData(selectedRows);
    }
  }, [selectedRows]);

  const visibleColumns = columns?.filter(column => !column.hide);

  const handleCheckbox = (row: any) => {
    return (
      selectedRows &&
      selectedRows.length > 0 &&
      selectedRows?.some((selectedRow: any) => {
        if (selectedRow?.[dataKey] === row[dataKey]) {
          return true;
        } else return false;
      })
    );
  };

  return (
    <div className={`${containerClasses} overflow-auto`}>
      <table
        style={{ width: '100%' }}
        // style={{ tableLayout: 'auto' }}
        // className={' w-full divide-y divide-gray-200 '}
      >
        <thead className={tHeadClasses}>
          <tr>
            {!hideCheckBoxes && (
              <th
                scope='col'
                className='py-3 pl-6 text-left text-xs font-medium tracking-wider text-gray-500'
              >
                <Checkbox
                  disabled={disableHeaderCheckbox}
                  checked={
                    data?.length > 0 && selectedRows?.length === data?.length
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(setSelectedRows(data));
                    } else {
                      dispatch(setSelectedRows([]));
                    }
                  }}
                />
              </th>
            )}
            {visibleColumns.map((column: any, index: any) => (
              <th
                // style={{ width: '20%' }}
                key={column.name}
                scope='col'
                className={`px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500 ${column.headerClasses ? column.headerClasses : 'w-[20%]'}`}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200 bg-white'>
          {data && data.length > 0 ? (
            data.map((row: any, i: number) => {
              return (
                <>
                  <tr
                    key={row[dataKey]}
                    onClick={() => handleRowClick(row)}
                    className={` ${!row.isActive && isBlur && 'blur-[1px]'} ${row.name === selectedRow && expandableTable && isChevronClicked && 'bg-[#F8FBF1]'} ${trClasses} h-10`}
                  >
                    {!hideCheckBoxes && (
                      <td className='pl-6'>
                        <Checkbox
                          disabled={disableCheckBoxes.includes(row[dataKey])}
                          checked={handleCheckbox(row)}
                          onChange={e => handleCheckboxChange(e, row)}
                        />
                      </td>
                    )}
                    {visibleColumns.map((column: any, index: any) => {
                      return (
                        <td
                          onClick={() => {
                            if (getSelectedRow) {
                              getSelectedRow(column.name, data, row);
                            }
                          }}
                          key={column.name}
                          className={`px-6 text-xs ${column.fieldClasses}`}
                        >
                          {column.selector
                            ? column.selector(row, tabRow, tabId, i) || '-'
                            : row[column.field] || '-'}
                        </td>
                      );
                    })}
                  </tr>
                  {row.name === selectedRow &&
                    expandableTable &&
                    isChevronClicked && (
                      <td
                        colSpan={columns.length + 1}
                        className={`${row.name === selectedRow && 'bg-[#F8FBF1]'} p-0`}
                      >
                        {expandableTable(row)}
                      </td>
                    )}
                </>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length + 1}
                className='px-6 py-4 text-center text-gray-500'
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
