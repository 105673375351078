import { InitalStateType } from './types';

export const intialState: InitalStateType = {
  inventoryDetails: {},
  selectedItemDetail: {
    itemId: null,
    itemType: '',
    itemUnit: '',
    itemName: '',
    imageURL: '',
    currentStock: 0,
    orderAccepted: 0
  },
  selectedUpdatedItemDetail: {
    itemID: null,
    itemType: '',
    itemUnit: '',
    itemName: '',
    imageLink: '',
    currentStock: 0,
    orderAccepted: 0,
    itemEstimates: []
  },
  selectedItemInventoryTxnDetail: [
    {
      itemID: 0,
      itemName: '',
      itemType: '',
      itemUnit: '',
      currentStock: 0,
      estimatesPending: 0,
      inventoryTxns: []
    }
  ],
  selectedItemDetailLoading: false,
  selectedUpdatedItemDetailLoading: false,
  selectedItemInventoryTxnDetailLoading: false,
  selectedItemInventoryTxnDetailClicked: true,
  isInventoryNameClicked: false,
  itemIDs: []
};
