import {
  CustomerApiResponse,
  CustomerItemGroupData,
  CustomerOutlet,
  PaymentDetails
} from '../redux/Slices/CustomersSlice/types';

import api from './api';
import {
  CustomerItemDetailsApiResponse,
  CustomerOrderDetailsResponse
} from './apiTypes';

export const customersApi = api.injectEndpoints({
  endpoints: builder => ({
    getCustomerTypes: builder.query({
      query: () => ({
        url: '/api/web/v1/customertype/getcustomertypes',
        method: 'GET'
      }),
      providesTags: ['GetCustomerTypes']
    }),
    addCustomerType: builder.mutation({
      query: (categorytypename: string) => ({
        url: `/api/web/v1/customertype/add?categorytypename=${categorytypename}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCustomerTypes']
    }),
    isCustomerTypeAvailable: builder.query({
      query: (customertypename: string) => ({
        url: `/api/web/v1/customertype/isavailable?customertypename=${customertypename}`,
        method: 'GET'
      })
    }),
    updateCustomerType: builder.mutation({
      query: ({
        categorytypename,
        id
      }: {
        categorytypename: string;
        id: number;
      }) => ({
        url: `/api/web/v1/customertype/update?categorytypename=${categorytypename}&id=${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCustomerTypes']
    }),
    updateCustomerTypeStatus: builder.mutation({
      query: ({ id, status }: { id: number; status: boolean }) => ({
        url: `/api/web/v1/customertype/updatestatus?id=${id}&status=${status}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCustomerTypes']
    }),
    addCuisineType: builder.mutation({
      query: (cuisinetypename: string) => ({
        url: `/api/web/v1/cuisinetype/add?cuisinetypename=${cuisinetypename}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCuisineTypes']
    }),
    isCuisineTypeAvailable: builder.query({
      query: (name: string) => ({
        url: `/api/web/v1/cuisinetype/isavailable?cuisinetype=${name}`,
        method: 'GET'
      })
    }),
    updateCuisineType: builder.mutation({
      query: ({ name, id }: { name: string; id: number }) => ({
        url: `/api/web/v1/cuisinetype/update?cuisinetype=${name}&id=${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCuisineTypes']
    }),
    updateCuisineTypeStatus: builder.mutation({
      query: ({ id, status }: { id: number; status: boolean }) => ({
        url: `/api/web/v1/cuisinetype/updatestatus?id=${id}&status=${status}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetCuisineTypes']
    }),
    getCustomerDashboardDetails: builder.query<CustomerApiResponse, any>({
      query: ({ search }: any = {}) => {
        let url = '/api/web/v1/customer/getcustomerdetails-dashboard';
        if (search) {
          url += `?searchtext=${search}`;
        }
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['GetCustomerDashboardDetails']
    }),
    getCustomerItemDashboardDetails: builder.query<CustomerApiResponse, void>({
      query: () => ({
        url: '/api/web/v1/customer/item/getcustomeritemdetails-dashboard',
        method: 'GET'
      })
    }),
    addCustomer: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/customer/add',
        method: 'POST',
        body: payload
      })
    }),
    assignItemGroupsToCustomer: builder.mutation({
      query: ({
        customerid,
        itemids
      }: {
        customerid: number;
        itemids: number[];
      }) => ({
        url: '/api/web/v1/customer/assignitemgroupstocustomer',
        method: 'POST',
        params: { customerid, itemids }
      }),
      invalidatesTags: ['GetCustomerItemGroups']
    }),
    getcuisinetypes: builder.query({
      query: () => ({
        url: '/api/web/v1/cuisinetype/getcuisinetypes',
        method: 'GET'
      }),
      providesTags: ['GetCuisineTypes']
    }),
    addCustomerOutlet: builder.mutation({
      query: ({
        customerId,
        payload
      }: {
        customerId: number;
        payload: CustomerOutlet;
      }) => ({
        url: `/api/web/v1/customer-outlet/addtocustomer?customerid=${customerId}`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['CustomerOutlets']
    }),
    getCustomerOutlets: builder.query({
      query: ({ customerId }: { customerId: number }) => ({
        url: `/api/web/v1/customer-outlet/getoutlets?customerid=${customerId}`,
        method: 'GET'
      }),
      providesTags: ['CustomerOutlets']
    }),
    getCustomerOutletsStaff: builder.query({
      query: ({ outletId }: { outletId: number }) => ({
        url: `/api/web/v1/customer-outlet/getstaffs?outletid=${outletId}`,
        method: 'GET'
      }),
      providesTags: ['GetCustomerOutletsStaff']
    }),
    getStaffDetailsForOutlets: builder.mutation({
      query: ({ payload }: { payload: number[] }) => ({
        url: '/api/web/v1/customer-outlet/getstaffdetailsforoutlets',
        method: 'POST',
        body: payload
      })
    }),
    getCustomerItemGroups: builder.query<
      CustomerItemGroupData,
      { customerid: number }
    >({
      query: ({ customerid }: { customerid: number }) => ({
        url: '/api/web/v1/itemgroup/assigned-itemgroups',
        method: 'GET',
        params: { customerid }
      }),
      providesTags: ['GetCustomerItemGroups']
    }),
    addCustomerOutletStaff: builder.mutation({
      query: ({
        outletId,
        payload
      }: {
        outletId: number;
        payload: CustomerOutlet;
      }) => ({
        url: `/api/web/v1/customer-outlet/staff/addtooutlet?outletid=${outletId}`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetCustomerOutletsStaff']
    }),
    getCustomerItemDetails: builder.query<
      CustomerItemDetailsApiResponse,
      { customerId: number }
    >({
      query: ({ customerId }: { customerId: number }) => ({
        url: `/api/web/v1/customer/item/getcustomeritemdetails?customerid=${customerId}`,
        method: 'GET'
      }),
      providesTags: ['GetCustomerItemDetails']
    }),
    isCustomerEmailAvailable: builder.query({
      query: ({ emailId }: { emailId: string }) => {
        let url = '/api/web/v1/customer/isemailavailable';
        if (emailId) {
          url += `?email=${emailId}`;
        }
        return {
          url,
          method: 'GET'
        };
      }
    }),
    isCustomerPanAvailable: builder.query({
      query: ({ panNumber }: { panNumber: string }) => ({
        url: `/api/web/v1/customer/ispanavailable?pannumber=${panNumber}`,
        method: 'GET'
      })
    }),
    isCustomerPhoneNumberAvailable: builder.query({
      query: ({ mobileNo }: { mobileNo: string }) => ({
        url: `/api/web/v1/customer/isphonenumberavailable?mobilenumber=${mobileNo}`,
        method: 'GET'
      })
    }),
    addOrUpdateCustomerItemDiscount: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/customer/item/addorupdateitemdiscountforcustomer',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetCustomerItemDetails']
    }),
    updatePaymentDetailsToCustomer: builder.mutation({
      query: ({
        customerID,
        payload
      }: {
        customerID: number;
        payload: PaymentDetails;
      }) => ({
        url: `/api/web/v1/customer/updatepaymentdetailstocustomer?customerID=${customerID}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['GetCustomerDashboardDetails']
    }),
    makePrimaryStaff: builder.mutation({
      query: ({ outletid, staffid }) => ({
        url: '/api/web/v1/customer-outlet/staff/makeprimary',
        method: 'PUT',
        params: { outletid, staffid }
      }),
      invalidatesTags: ['GetCustomerItemDetails']
    }),
    fetchCustomerOrders: builder.query<
      CustomerOrderDetailsResponse,
      number | null
    >({
      query: customerid => ({
        url: '/api/web/v1/orders/getcustomerorders',
        method: 'GET',
        params: { customerid }
      })
    })
  })
});

export const {
  useGetCustomerTypesQuery,
  useLazyGetCustomerTypesQuery,
  useAddCustomerMutation,
  useGetCustomerDashboardDetailsQuery,
  useLazyGetCustomerDashboardDetailsQuery,
  useAssignItemGroupsToCustomerMutation,
  useGetcuisinetypesQuery,
  useLazyGetcuisinetypesQuery,
  useAddCustomerOutletMutation,
  useGetCustomerOutletsQuery,
  useLazyGetCustomerOutletsQuery,
  useAddCustomerOutletStaffMutation,
  useGetCustomerItemDashboardDetailsQuery,
  useLazyGetCustomerItemDashboardDetailsQuery,
  useGetCustomerItemGroupsQuery,
  useLazyGetCustomerItemGroupsQuery,
  useGetCustomerOutletsStaffQuery,
  useGetStaffDetailsForOutletsMutation,
  useAddCustomerTypeMutation,
  useAddCuisineTypeMutation,
  useIsCuisineTypeAvailableQuery,
  useLazyIsCuisineTypeAvailableQuery,
  useUpdateCuisineTypeMutation,
  useUpdateCuisineTypeStatusMutation,
  useLazyIsCustomerTypeAvailableQuery,
  useUpdateCustomerTypeMutation,
  useUpdateCustomerTypeStatusMutation,
  useLazyGetCustomerItemDetailsQuery,
  useLazyIsCustomerEmailAvailableQuery,
  useLazyIsCustomerPanAvailableQuery,
  useLazyIsCustomerPhoneNumberAvailableQuery,
  useAddOrUpdateCustomerItemDiscountMutation,
  useUpdatePaymentDetailsToCustomerMutation,
  useMakePrimaryStaffMutation,
  useFetchCustomerOrdersQuery
} = customersApi;
