import { createSlice } from '@reduxjs/toolkit';

const headerSlice = createSlice({
  name: 'header',
  initialState: {
    headerTitle: 'Items'
  },
  reducers: {
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload;
    }
  }
});

export const { setHeaderTitle } = headerSlice.actions;

export default headerSlice.reducer;
