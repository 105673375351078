import somethingWentWrongImg from '../assets/images/somethingwentwrong.jpeg';
const SomethingWentWrongPage = () => {
  return (
    <div className='flex h-screen items-center justify-center'>
      <img src={somethingWentWrongImg} alt='somethingWentWrong' />
    </div>
  );
};

export default SomethingWentWrongPage;
