import { IoChevronDown } from 'react-icons/io5';
import { useDispatch } from 'react-redux';

import { setIsAddCustomerButtonClicked } from '../../redux/Slices/CustomersSlice/customersSlice';
import { CustomerOutlet } from '../../redux/Slices/CustomersSlice/types';
import { useAppSelector } from '../../redux/store/hooks';
import {
  useGetCustomerDashboardDetailsQuery,
  useLazyGetCustomerOutletsQuery
} from '../../services/customersApi';
import CommonPopover from '../Common/CommonPopover';
import Table from '../Common/Table';

const PaymentsDashboard = () => {
  useGetCustomerDashboardDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const { customerDashboardDetails, allOutletsofCustomer } = useAppSelector(
    state => state.root.customers
  );
  const dispatch = useDispatch();
  const [getCustomerOutlets] = useLazyGetCustomerOutletsQuery();
  const fieldClasses = 'text-sm text-[#000000]';
  const handleNameClick = (row: any) => {
    // console.log(row);
  };
  const outletColumns = [
    {
      field: 'name',
      name: `Outlets (${allOutletsofCustomer?.length})`,
      sortable: false,
      fieldClasses
    },
    {
      field: 'outletAddress',
      name: 'Address',
      sortable: false,
      selector: (row: CustomerOutlet) => {
        return (
          <div>
            <div className='flex items-center'>
              <p>{row?.outletAddress?.outletName}</p>,
              <p>{row?.outletAddress?.floorNumber}</p>,
            </div>
            <div className='flex items-center'>
              <p>{row?.outletAddress?.city}</p>,
              <p>{row?.outletAddress?.state}</p>,
            </div>
            <p>{row?.outletAddress?.pincode}</p>
          </div>
        );
      },
      fieldClasses
    },
    {
      field: 'poccontact',
      name: 'POC Contact',
      sortable: false,
      fieldClasses
    }
  ];

  const columns = [
    {
      field: 'businessName',
      name: 'Customer/Business Name',
      selector: (row: any) => {
        return (
          <button onClick={() => handleNameClick(row)}>
            <div className='flex items-center'>
              <span className='font-medium underline'>{row?.businessName}</span>
            </div>
          </button>
        );
      },
      sortable: false,
      fieldClasses: '!underline !text-xs !text-[#0079E9]'
    },
    {
      field: 'outlets',
      name: 'Outlets',
      sortable: false,
      selector: (row: any) => {
        return (
          row.outlets && (
            <div className='flex items-center'>
              <CommonPopover
                position='bottom start'
                label={
                  <div
                    onClick={() =>
                      getCustomerOutlets({ customerId: row.customerID })
                    }
                    className='flex items-center'
                  >
                    {row.outlets} <IoChevronDown className='ml-2' />
                  </div>
                }
              >
                <div className='rounded-xl border border-[#E3E5E9] bg-white'>
                  <p className='font-meduim px-4 py-2'>{row.businessName}</p>
                  <Table
                    columns={outletColumns}
                    data={allOutletsofCustomer}
                    dataKey='id'
                    hideCheckBoxes
                    containerClasses='h-full rounded-b-xl w-full'
                  />
                </div>
              </CommonPopover>
            </div>
          )
        );
      },
      fieldClasses
    },
    {
      field: 'businessPOC',
      name: 'Business POC',
      sortable: false,
      fieldClasses
    },
    {
      field: 'pocContact',
      name: 'POC Contact',
      sortable: false,
      fieldClasses
    },
    {
      field: 'creditLimit',
      name: 'Credit Limit',
      sortable: false,
      fieldClasses
    },
    {
      field: 'outstanding',
      name: 'Outstanding',
      sortable: false,
      fieldClasses
    },
    {
      field: 'status',
      name: 'Status',
      sortable: false,
      fieldClasses
    }
  ];

  const getSelectedRow = (id: string, data: any) => {
    // console.log(id, data, "id && data");
  };

  const getSelectedData = (data: any) => {
    // console.log(data,'data')
  };

  const disableCheckBoxes = () => {
    return [];
    // disable checkboxes
  };

  const handleClickAddCustomer = () => {
    dispatch(setIsAddCustomerButtonClicked(true));
  };

  return (
    <div className='w-full'>
      <div className='pt-6'>
        <div className='mx-6 flex justify-between rounded-t-xl border-l border-r border-t border-[#E3E5E9] p-4'>
          <div>
            <p>
              Customer:{' '}
              <span className='font-bold'>
                {customerDashboardDetails?.data?.length}
              </span>
            </p>
          </div>
        </div>
        <div className='px-6 pb-6'>
          <Table
            columns={columns}
            data={customerDashboardDetails?.data}
            getSelectedRow={getSelectedRow}
            getSelectedData={getSelectedData}
            disableHeaderCheckbox
            disableCheckBoxes={disableCheckBoxes()}
            dataKey='customerID'
            hideCheckBoxes
            containerClasses='h-full rounded-b-xl w-full border border-[#E3E5E9]'
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentsDashboard;
