import { getItem } from './storage';

const get_profile = () => {
  const host = window.location.host;
  if (host === 'localhost:3000') return 'dev';
  if (host === 'farmtheory-core.web.app') return 'dev';
  if (host === 'ft-uat-56ec0.web.app') return 'uat';
  if (host === 'admin.livingtheory.in') return 'prod';
  return 'unknown';
};

let be_url = 'https://dev.api.livingtheory.in';

const profile = get_profile();
switch (profile) {
  case 'dev':
    be_url = 'https://dev.api.livingtheory.in';
    break;
  case 'uat':
    be_url = 'https://uat.api.livingtheory.in';
    break;
  case 'prod':
    be_url = 'https://api.livingtheory.in';
    break;
  default:
    be_url = 'https://dev.api.livingtheory.in';
    break;
}

export const serviceUrl = be_url;

export const genericHeaders = () => {
  if (getItem('token') != null) {
    return {
      'Content-Type': 'application/json'
      //   Authorization: 'Bearer ' + getItem('token')
    };
  }
  return {
    'Content-Type': 'application/json'
  };
};
