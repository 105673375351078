import api from './api';

export const lookupsApi = api.injectEndpoints({
  endpoints: builder => ({
    getDesignationTypes: builder.query({
      query: () => ({
        url: '/api/web/v1/designation/getdesignationtypes',
        method: 'GET'
      }),
      providesTags: ['GetDesignationTypes']
    }),
    addDesignationType: builder.mutation({
      query: (name: string) => ({
        url: `/api/web/v1/designation/add?designation=${name}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetDesignationTypes']
    }),
    isDesignationTypeAvailable: builder.query({
      query: (name: string) => ({
        url: `/api/web/v1/designation/isavailable?designationtype=${name}`,
        method: 'GET'
      })
    }),
    updateDesignationType: builder.mutation({
      query: ({ name, id }: { name: string; id: number }) => ({
        url: `/api/web/v1/designation/update?designationtype=${name}&id=${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetDesignationTypes']
    }),
    updateDesignationTypeStatus: builder.mutation({
      query: ({ id, status }: { id: number; status: boolean }) => ({
        url: `/api/web/v1/designation/updatestatus?id=${id}&status=${status}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetDesignationTypes']
    }),
    getMov: builder.query({
      query: () => ({
        url: '/api/web/v1/mov/getall',
        method: 'GET'
      }),
      providesTags: ['GetMov']
    }),
    addMov: builder.mutation({
      query: (value: number) => ({
        url: `/api/web/v1/mov/add?maxValue=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetMov']
    }),
    updateMov: builder.mutation({
      query: ({ value, id }: { value: number; id: number }) => ({
        url: `/api/web/v1/mov/update?id=${id}&value=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetMov']
    }),
    getAccountManager: builder.query({
      query: () => ({
        url: '/api/web/v1/manager/getall',
        method: 'GET'
      }),
      providesTags: ['GetAccountManager']
    }),
    getDeliveryTimeSlot: builder.query({
      query: () => ({
        url: '/api/web/v1/timeslot/getall',
        method: 'GET'
      }),
      providesTags: ['GetDeliveryTimeSlot']
    }),
    updateDeliveryTimeSlot: builder.mutation({
      query: ({ value, id }: { value: string; id: number }) => ({
        url: `/api/web/v1/timeslot/update?id=${id}&timeSlot=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetDeliveryTimeSlot']
    }),
    updateAccountManager: builder.mutation({
      query: ({ value, id }: { value: string; id: number }) => ({
        url: `/api/web/v1/manager/update?id=${id}&value=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetAccountManager']
    }),
    addAccountManager: builder.mutation({
      query: (value: string) => ({
        url: `/api/web/v1/manager/add?name=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetAccountManager']
    }),
    addDeliveryTimeSlot: builder.mutation({
      query: (value: string) => ({
        url: `/api/web/v1/timeslot/add?timeSlot=${value}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetDeliveryTimeSlot']
    })
  })
});

export const {
  useGetDesignationTypesQuery,
  useLazyGetDesignationTypesQuery,
  useAddDesignationTypeMutation,
  useUpdateDesignationTypeMutation,
  useUpdateDesignationTypeStatusMutation,
  useLazyIsDesignationTypeAvailableQuery,
  useGetMovQuery,
  useAddMovMutation,
  useUpdateMovMutation,
  useGetAccountManagerQuery,
  useLazyGetAccountManagerQuery,
  useGetDeliveryTimeSlotQuery,
  useLazyGetDeliveryTimeSlotQuery,
  useUpdateDeliveryTimeSlotMutation,
  useUpdateAccountManagerMutation,
  useAddAccountManagerMutation,
  useAddDeliveryTimeSlotMutation
} = lookupsApi;
