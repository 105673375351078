import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

import { clearStorage } from '../utils/storage';

type ExpectedError = {
  data: {
    data: string;
  };
};

let has401ErrorDisplayed = false;
const blackListLoader: string[] = [];
const blackListToast: string[] = [];
/**
 * It is used to customize the customBase query
 * @param props It will receive props form base quey by default
 * * @returns function of badquery
 */
const customBaseQuery = (props: FetchBaseQueryArgs) => {
  const baseQuery = fetchBaseQuery({ ...props });
  /**
   *
   * @param args
   * @param api
   * @param extraOptions
   * @returns
   */
  const fn: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    if (!blackListLoader.includes(api.endpoint)) {
      api.dispatch({ type: 'loader/setLoader', payload: true });
    }
    const result = (await baseQuery(args, api, extraOptions)) as any;
    try {
      if (!has401ErrorDisplayed && result?.error?.status === 403) {
        toast.error('Your session has expired');
        clearStorage();
        api.dispatch({ type: 'auth/setIsLoggedIn', payload: false });
        has401ErrorDisplayed = true;
      }
      setTimeout(() => {
        has401ErrorDisplayed = false;
      }, 4000);
    } catch (error) {
      if ((error as ExpectedError).data) {
        toast.error((error as ExpectedError).data.data);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      api.dispatch({ type: 'loader/setLoader', payload: false });
    }
    return result;
  };
  return fn;
};

export default customBaseQuery;
