import api from './api';
import { GetOrderByStatusResponse, OrderMetricsResponse } from './apiTypes';

export const ordersListApi = api.injectEndpoints({
  endpoints: builder => ({
    dashboardMetrics: builder.query({
      query: () => ({
        url: '/api/web/v1/orders/dashboardmetrics',
        method: 'GET'
      })
    }),
    getAllOrders: builder.query({
      query: () => ({
        url: '/api/web/v1/orders/allorders',
        method: 'GET'
      })
    }),
    getOrdersByStatus: builder.query<GetOrderByStatusResponse, number>({
      query: status => ({
        url: '/api/web/v1/orders/ordersbystatus',
        method: 'GET',
        params: { status }
      }),
      providesTags: ['GetOrderByStatus']
    }),
    updateOrder: builder.mutation({
      query: ({ status, payload }: { status: number; payload: any }) => ({
        url: '/api/web/v1/orders/updateorder',
        method: 'PUT',
        body: payload,
        params: { status }
      }),
      invalidatesTags: ['GetOrderByStatus', 'GetOrderMetrics']
    }),

    getOrderMetrics: builder.query<OrderMetricsResponse, void>({
      query: () => ({
        url: '/api/web/v1/orders/dashboardmetrics',
        method: 'GET'
      }),
      providesTags: ['GetOrderMetrics']
    }),
    viewInvoice: builder.query({
      query: (orderId: any) => ({
        url: '/api/web/v1/orders/getinvoice',
        method: 'GET',
        params: { orderId }
      })
    }),
    generateInvoice: builder.mutation({
      query: (orderid: any) => ({
        url: '/api/web/v1/orders/generate-invoice',
        method: 'POST',
        params: { orderid }
      }),
      invalidatesTags: ['GetOrderByStatus']
    })
  })
});

export const {
  useDashboardMetricsQuery,
  useGetAllOrdersQuery,
  useGetOrdersByStatusQuery,
  useUpdateOrderMutation,
  useLazyGetOrdersByStatusQuery,
  useGetOrderMetricsQuery,
  useLazyViewInvoiceQuery,
  useGenerateInvoiceMutation
} = ordersListApi;
