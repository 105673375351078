import { combineReducers } from '@reduxjs/toolkit';

import AgentsSlice from '../Slices/AgentsSlice';
import AuthSlice from '../Slices/AuthSlice';
import categoriesSlice from '../Slices/CategoriesSlice/categoriesSlice';
import customersSlice from '../Slices/CustomersSlice/customersSlice';
import headerSlice from '../Slices/headerSlice';
import inventorySlice from '../Slices/InventorySlice/inventorySlice';
import itemsSlice from '../Slices/ItemsSlice/itemsSlice';
import ordersslice from '../Slices/OrdersSlice/ordersSlice';

const rootReducer = combineReducers({
  header: headerSlice,
  items: itemsSlice,
  categories: categoriesSlice,
  customers: customersSlice,
  orders: ordersslice,
  inventory: inventorySlice,
  agents: AgentsSlice,
  auth: AuthSlice
});

export default rootReducer;
