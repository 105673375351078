import { CreditCycleType } from './types';

export const customersData = {
  customerType: 0,
  email: '',
  gstinDocument: {
    documentLink: '',
    documentName: ''
  },
  legalBusinessName: '',
  mobileNumber: '',
  ownerOrBusinessPOC: '',
  panDocument: {
    documentLink: '',
    documentName: ''
  },
  paymentDetails: {
    creditCycleType: null,
    dueDays: null,
    isPostpaid: false,
    tnxPeriod: null,
    creditLimit: null
  },
  registeredAddress: '',
  zohoCustomerId: '',
  brandName: '',
  accountManagerId: ''
};

export const customerTypes = {
  id: null,
  type: '',
  isActive: false
};

export const accountManagerTypes = {
  id: null,
  name: '',
  isActive: false
};

export const deliveryTimeSlotTypes = {
  id: null,
  timeSlot: '',
  isActive: false
};

export const creditCycleTypes: CreditCycleType[] = [
  {
    id: 1,
    type: 'DAILY',
    isActive: true
  },
  {
    id: 2,
    type: 'WEEKLY',
    isActive: false
  },
  {
    id: 3,
    type: 'MONTHLY',
    isActive: false
  },
  {
    id: 4,
    type: 'YEARLY',
    isActive: false
  }
];

export const customerDashBoardDetails = {
  message: '',
  status: '',
  data: [
    {
      customerID: 0,
      businessPOC: '',
      businessName: '',
      outlets: '',
      staff: '',
      pocContact: '',
      registeredAddress: '',
      assignedItemGroups: ''
    }
  ]
};

export const customerOutlets = {};
