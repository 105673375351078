import api from './api';
import {
  ApiResponse,
  ItemGroupByPacksApiResponse,
  ItemGroupsApiResponse,
  ItemPricingDetails,
  UpdateItemgroupStatusParams
} from './apiTypes';

export const itemsApi = api.injectEndpoints({
  endpoints: builder => ({
    isTemAvailable: builder.query({
      query: itemName => ({
        url: `/api/web/v1/item/isavailable?itemname=${itemName}`,
        method: 'GET'
      })
    }),
    saveItems: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/item/saveitems',
        method: 'POST',
        body: payload
      })
    }),
    getItemDetailsByGroup: builder.query({
      query: ({ search }: any = {}) => {
        let url = '/api/web/v1/item/getitemdetailsbygroup';
        if (search) {
          url += `?searchtext=${search}`;
        }
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['GetItemDetailsByGroup']
    }),
    getItemDetailsByPacks: builder.query<ItemGroupByPacksApiResponse, null>({
      query: () => ({
        url: '/api/web/v1/item/getitemdetailsbypacks',
        method: 'GET'
      }),
      providesTags: ['GetItemDetailsByPacks']
    }),
    getItemGroupDashboardDetails: builder.query<ItemGroupsApiResponse, void>({
      query: () => ({
        url: '/api/web/v1/itemgroup/filter',
        method: 'GET'
      }),
      providesTags: ['GetItemGroupDashboardDetails']
    }),
    getItemGroupsDashboardDetails: builder.query<ItemGroupsApiResponse, any>({
      query: ({ search }: any = {}) => {
        let url = '/api/web/v1/itemgroup/getitemgroups-dashboard';
        if (search) {
          url += `?flexitext=${search}`;
        }
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['GetItemGroupDashboardDetails']
    }),
    addmultiplepacksizes: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/item/addmultiplepacksizes',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetItemDetailsByGroup']
    }),
    isItemGroupAvailable: builder.query({
      query: (name: string) => ({
        url: `api/web/v1/itemgroup/isavailable?itemgroup=${name}`,
        method: 'GET'
      })
    }),
    createItemGroup: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/itemgroup/create',
        method: 'POST',
        body: payload
      })
    }),
    createItemPacksGroup: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/itemgroup/createitempacksgroup',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetItemGroupDashboardDetails']
    }),
    getitemPricingdetails: builder.query<ApiResponse, void>({
      query: (categoryId: any) => ({
        url: 'api/web/v1/item/',
        method: 'GET'
      })
    }),
    getItemdetailsinpricing: builder.mutation<ItemPricingDetails, number[]>({
      query: payload => ({
        url: 'api/web/v1/item/itemdetailsinpricing',
        method: 'POST',
        body: payload
      })
    }),
    updateItemBasePrice: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/pricing/updatebaseprice?baseprice=${payload.price}&itemid=${payload.itemid}`,
        method: 'POST',
        body: payload
      })
    }),
    updatePackSizePrice: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/pricing/updatepacksizeprice?itemid=${payload.itemId}`,
        method: 'POST',
        body: payload.packPrices
      })
    }),
    getBasePriceMetrics: builder.query({
      query: () => ({
        url: 'api/web/v1/item/getbasepricemetrics',
        method: 'GET'
      })
    }),
    getItemsBasePriceUdpatedLastWeek: builder.query({
      query: () => ({
        url: 'api/web/v1/item/getindividualitemsbasepriceudpatedlastweek',
        method: 'GET'
      })
    }),
    getItemsWithoutBasePrice: builder.query({
      query: () => ({
        url: 'api/web/v1/item/getindividualitemswithoutbaseprice',
        method: 'GET'
      })
    }),
    updateBasePriceAndAssociatedPackPrices: builder.mutation({
      query: ({ baseprice, itemid }) => ({
        url: 'api/web/v1/item/pricing/updatebasepriceandassoicatedpackprices',
        method: 'PUT',
        params: { baseprice, itemid }
      }),
      invalidatesTags: ['GetItemDetailsByGroup']
    }),
    getItemgroupById: builder.query({
      query: ({ itemgroupid, search }: any) => {
        let url = 'api/web/v1/itemgroup/get-itemgroup';
        if (search) {
          url += `?flexitext=${search}`;
        }
        return {
          url,
          method: 'GET',
          params: { itemgroupid }
        };
      }
    }),
    updateItemgroup: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/itemgroup/updateitempacksgroup',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetItemGroupDashboardDetails']
    }),
    updateItemgroupStatus: builder.mutation({
      query: (params: UpdateItemgroupStatusParams) => ({
        url: 'api/web/v1/itemgroup/statusupdate',
        method: 'PUT',
        params
      }),
      invalidatesTags: ['GetItemGroupDashboardDetails']
    }),
    getItemDetailsEdit: builder.query<any, number[]>({
      query: itemids => ({
        url: 'api/web/v1/item/getitemdetails-edit',
        method: 'GET',
        params: { itemids }
      })
    }),
    updateItems: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/item/updateitems',
        method: 'POST',
        body: payload
      })
    }),
    updateItemsGeneralDetails: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/item/updateitems-generaldetails',
        method: 'POST',
        body: payload
      })
    }),
    itemSwitchStatus: builder.mutation({
      query: ({ itemids, makeItemsActive }) => ({
        url: '/api/web/v1/item/changestatus',
        method: 'PUT',
        params: { makeItemsActive },
        body: itemids
      }),
      invalidatesTags: ['GetItemDetailsByGroup']
    }),
    addPackSizes: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/addorupdatepacksizes?itemid=${payload.itemId}`,
        method: 'POST',
        body: payload.packSizes
      })
    }),
    isSkuAvailable: builder.query({
      query: sKU => ({
        url: `/api/web/v1/item/isskuidavailable?skuid=${sKU}`,
        method: 'GET'
      })
    }),
    addPackSizeByItemID: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/addpacksize?itemid=${payload.itemId}`,
        method: 'POST',
        body: payload.pack
      }),
      invalidatesTags: ['GetItemPacksByID']
    }),
    updatePackSizeByItemID: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/updateitempack?itemid=${payload.itemId}`,
        method: 'PUT',
        body: payload.pack
      }),
      invalidatesTags: ['GetItemPacksByID']
    }),
    deletePackSizeByItemID: builder.mutation({
      query: payload => ({
        url: `api/web/v1/item/deleteitempack?itemid=${payload.itemId}`,
        method: 'DELETE',
        body: payload.pack
      }),
      invalidatesTags: ['GetItemPacksByID']
    }),
    getItemPacksByID: builder.query({
      query: payload => ({
        url: `api/web/v1/item/getitempacks?itemid=${payload.itemId}`,
        method: 'GET'
      }),
      providesTags: ['GetItemPacksByID']
    })
  })
});

export const {
  useUpdateItemsGeneralDetailsMutation,
  useUpdateItemsMutation,
  useLazyGetItemDetailsEditQuery,
  useGetItemGroupsDashboardDetailsQuery,
  useLazyGetItemGroupsDashboardDetailsQuery,
  useUpdateItemgroupStatusMutation,
  useUpdateItemgroupMutation,
  useGetItemgroupByIdQuery,
  useLazyGetItemgroupByIdQuery,
  useIsTemAvailableQuery,
  useLazyIsTemAvailableQuery,
  useSaveItemsMutation,
  useGetItemDetailsByGroupQuery,
  useLazyGetItemDetailsByGroupQuery,
  useAddmultiplepacksizesMutation,
  useGetItemGroupDashboardDetailsQuery,
  useLazyIsItemGroupAvailableQuery,
  useCreateItemGroupMutation,
  useGetitemPricingdetailsQuery,
  useGetItemdetailsinpricingMutation,
  useUpdateItemBasePriceMutation,
  useUpdatePackSizePriceMutation,
  useLazyGetItemGroupDashboardDetailsQuery,
  useGetBasePriceMetricsQuery,
  useLazyGetItemsBasePriceUdpatedLastWeekQuery,
  useLazyGetItemsWithoutBasePriceQuery,
  useUpdateBasePriceAndAssociatedPackPricesMutation,
  useCreateItemPacksGroupMutation,
  useGetItemDetailsByPacksQuery,
  useItemSwitchStatusMutation,
  useAddPackSizesMutation,
  useLazyIsSkuAvailableQuery,
  useGetItemPacksByIDQuery,
  useAddPackSizeByItemIDMutation,
  useDeletePackSizeByItemIDMutation,
  useUpdatePackSizeByItemIDMutation
} = itemsApi;
